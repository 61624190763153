exports.components = {
  "component---src-pages-classes-tsx": () => import("./../../../src/pages/classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-competition-info-tsx": () => import("./../../../src/pages/competition-info.tsx" /* webpackChunkName: "component---src-pages-competition-info-tsx" */),
  "component---src-pages-competition-results-2011-tsx": () => import("./../../../src/pages/competition-results/2011.tsx" /* webpackChunkName: "component---src-pages-competition-results-2011-tsx" */),
  "component---src-pages-competition-results-2012-tsx": () => import("./../../../src/pages/competition-results/2012.tsx" /* webpackChunkName: "component---src-pages-competition-results-2012-tsx" */),
  "component---src-pages-competition-results-2013-tsx": () => import("./../../../src/pages/competition-results/2013.tsx" /* webpackChunkName: "component---src-pages-competition-results-2013-tsx" */),
  "component---src-pages-competition-results-2014-tsx": () => import("./../../../src/pages/competition-results/2014.tsx" /* webpackChunkName: "component---src-pages-competition-results-2014-tsx" */),
  "component---src-pages-competition-results-2015-tsx": () => import("./../../../src/pages/competition-results/2015.tsx" /* webpackChunkName: "component---src-pages-competition-results-2015-tsx" */),
  "component---src-pages-competition-results-2016-tsx": () => import("./../../../src/pages/competition-results/2016.tsx" /* webpackChunkName: "component---src-pages-competition-results-2016-tsx" */),
  "component---src-pages-competition-results-2017-tsx": () => import("./../../../src/pages/competition-results/2017.tsx" /* webpackChunkName: "component---src-pages-competition-results-2017-tsx" */),
  "component---src-pages-competition-results-2018-tsx": () => import("./../../../src/pages/competition-results/2018.tsx" /* webpackChunkName: "component---src-pages-competition-results-2018-tsx" */),
  "component---src-pages-competition-results-2019-tsx": () => import("./../../../src/pages/competition-results/2019.tsx" /* webpackChunkName: "component---src-pages-competition-results-2019-tsx" */),
  "component---src-pages-covid-tsx": () => import("./../../../src/pages/covid.tsx" /* webpackChunkName: "component---src-pages-covid-tsx" */),
  "component---src-pages-event-venues-tsx": () => import("./../../../src/pages/event-venues.tsx" /* webpackChunkName: "component---src-pages-event-venues-tsx" */),
  "component---src-pages-housing-tsx": () => import("./../../../src/pages/housing.tsx" /* webpackChunkName: "component---src-pages-housing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instructors-tsx": () => import("./../../../src/pages/instructors.tsx" /* webpackChunkName: "component---src-pages-instructors-tsx" */),
  "component---src-pages-local-faire-tsx": () => import("./../../../src/pages/local-faire.tsx" /* webpackChunkName: "component---src-pages-local-faire-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-pricing-and-tshirts-tsx": () => import("./../../../src/pages/pricing-and-tshirts.tsx" /* webpackChunkName: "component---src-pages-pricing-and-tshirts-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-registration-complete-tsx": () => import("./../../../src/pages/registration-complete.tsx" /* webpackChunkName: "component---src-pages-registration-complete-tsx" */),
  "component---src-pages-safety-report-tsx": () => import("./../../../src/pages/safety-report.tsx" /* webpackChunkName: "component---src-pages-safety-report-tsx" */),
  "component---src-pages-safety-tsx": () => import("./../../../src/pages/safety.tsx" /* webpackChunkName: "component---src-pages-safety-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */)
}

